import React from "react";

interface Props {
  color?: string;
  size?: number;
}
function GraduationSvg(props: Props) {
  const { color = "#27AE60", size = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill={color}
        d="M40 14.134v19.2a1.667 1.667 0 01-3.334 0V19.587l-12.333 5.894a8.333 8.333 0 01-4.295 1.156 8.787 8.787 0 01-4.5-1.231l-12.3-5.855a6.152 6.152 0 010-10.834l12.428-5.93a8.71 8.71 0 018.804.075L36.76 8.717A6.278 6.278 0 0140 14.134zM20.037 29.967a12.1 12.1 0 01-6.075-1.62l-7.295-3.481v4.5a8.345 8.345 0 005.864 7.963A25.931 25.931 0 0020 38.334a25.93 25.93 0 007.468-1.012 8.345 8.345 0 005.865-7.963v-4.485l-7.431 3.55a11.639 11.639 0 01-5.866 1.547v-.004z"
      />
    </svg>
  );
}

export default GraduationSvg;
