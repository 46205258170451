import React from "react";
interface Props {
  color?: string;
  size?: number;
}
function LockSvg(props: Props) {
  const { color = "#EB5757", size = 24 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill={color}
        d="M31.666 14.04v-2.373C31.666 5.223 26.443 0 20 0 13.556 0 8.333 5.223 8.333 11.667v2.373a8.333 8.333 0 00-5 7.627v10A8.343 8.343 0 0011.666 40h16.667a8.343 8.343 0 008.333-8.333v-10a8.333 8.333 0 00-5-7.627zm-10 14.293a1.667 1.667 0 01-3.333 0V25a1.667 1.667 0 113.333 0v3.333zm6.667-15H11.666v-1.666a8.333 8.333 0 1116.667 0v1.666z"
      />
    </svg>
  );
}

export default LockSvg;
