import React from "react";
interface Props {
  color?: string;
  size?: number;
}
function ChartTreeSvg(props: Props) {
  const { color = "#2F80ED", size = 24 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <g clipPath="url(#clip0_6431_18212)">
        <path
          fill={color}
          d="M36.667 30.307V30a8.344 8.344 0 00-8.334-8.334h-6.666V19.85a10 10 0 10-3.334 0v1.816h-6.666A8.344 8.344 0 003.333 30v.307a5 5 0 103.334 0V30a5 5 0 015-5h6.666v5.307a5 5 0 103.334 0V25h6.666a5 5 0 015 5v.307a5 5 0 103.334 0z"
        />
      </g>
      <defs>
        <clipPath id="clip0_6431_18212">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ChartTreeSvg;
