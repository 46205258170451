import React from "react";

interface Props {
  color?: string;
  size?: number;
}

function TruckSvg(props: Props) {
  const { color = "#2F80ED", size = 24 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <g clipPath="url(#clip0_6424_18466)">
        <path
          fill={color}
          d="M25 29.999H6.667A6.667 6.667 0 010 23.332V10a8.333 8.333 0 018.333-8.333h8.334A8.334 8.334 0 0125 9.999v20zm15-11.667v-1.666a8.333 8.333 0 00-8.333-8.334h-3.334v10H40zm-11.667 3.334v8.333h5A6.667 6.667 0 0040 23.332v-1.666H28.333zM6.763 33.332a4.04 4.04 0 00-.096.834 4.167 4.167 0 108.333 0 4.036 4.036 0 00-.097-.834h-8.14zm18.334 0a4.036 4.036 0 00-.097.834 4.167 4.167 0 108.333 0 4.043 4.043 0 00-.096-.834h-8.14z"
        />
      </g>
      <defs>
        <clipPath id="clip0_6424_18466">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default TruckSvg;
