import React from "react";

interface Props {
  color?: string;
  size?: number;
}

function Icon(props: Props) {
  const { color = "#27AE60", size = 24 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill={color}
        d="M26.666 21.666a8.333 8.333 0 01-13.333 0 8.261 8.261 0 01-11.667 1.628v8.372a8.343 8.343 0 008.333 8.333h20a8.343 8.343 0 008.334-8.334V23.29a8.26 8.26 0 01-11.667-1.623z"
      />
      <path
        fill={color}
        d="M36.166 5.219A6.625 6.625 0 0029.653 0h-1.32v5a1.667 1.667 0 11-3.334 0V0H15v5a1.666 1.666 0 11-3.333 0V0h-1.32a6.625 6.625 0 00-6.513 5.22l-2.13 9.613-.037 1.867a5 5 0 1010-.034 1.666 1.666 0 113.333 0 5 5 0 0010 0 1.666 1.666 0 113.334 0 5 5 0 0010 0V15.18l-2.167-9.96z"
      />
    </svg>
  );
}

export default Icon;
