import classNames from "classnames";
import { css } from "common/utils/stitches.config";
import { Tag, Text } from "components/home-element";
import Image from "next/legacy/image";
import { useTranslation } from "react-i18next";

const social = ["facebook", "instagram", "twitter", "youtube"];
// const download = ["google-play", "app-store"];
const buy = [
  {
    name: "tokopedia",
    url: "/assets/img-beli-1.png",
    imageWidth: 113,
    imageHeigth: 100,
    link: "https://www.tokopedia.com/qrid",
  },
  {
    name: "shopee",
    url: "/assets/img-beli-2.png",
    imageWidth: 95,
    imageHeigth: 100,
    link: "https://shopee.co.id/qrid.co",
  },
];
const download = [
  {
    name: "google-play",
    url: "/assets/img-play-store.png",
    imageWidth: 200,
    imageHeigth: 58,
    link: "https://play.google.com/store/apps/details?id=co.qrid.android",
  },
  {
    name: "app-store",
    url: "/assets/img-app-store.png",
    imageWidth: 200,
    imageHeigth: 58,
    link: "https://apps.apple.com/id/app/qrid/id1592667444?l=id",
  },
];
const contact = [
  {
    text: "PT QRID Teknologi Indonesia",
  },
  {
    text: "Cyber 2 Tower 17th Floor",
  },
  {
    text: "Jl HR Rasuna Said Blok X5 Kav 13",
  },
  {
    text: "Jakarta Selatan",
  },
  {
    text: "Email: hello@qrid.co",
  },
  {
    text: "WhatsApp: 0811 164 1188",
  },
];

const credit = [
  {
    text: "Syarat & Ketentuan",
    link: "/tnc",
  },
  {
    text: "Kebijakan Privasi",
    link: "/privacy",
  },
];
export default function Footer() {
  const { t } = useTranslation();

  return (
    <section id="contact">
      <div className={styles.waveContainer()} />
      <div className={styles.container()}>
        <div className={styles.content()}>
          <div className={styles.innerContainer()}>
            <div className={styles.leftContainer()}>
              <div className={styles.footerTitle()}>{t("home.about_qrid")}</div>
              <div className={styles.footerText()}>
                {t("home.qrid_definition")}
              </div>
            </div>
            <div className={styles.middleContainer()}>
              <div className={styles.footerTitle()}>
                {t("home.qrid_download")}
              </div>
              <div className={styles.downloadImageContainer()}>
                {download.map((item) => {
                  return (
                    <div key={item.name} className={styles.buyImageWrapper()}>
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <Image
                          src={item.url}
                          width={item.imageWidth}
                          height={item.imageHeigth}
                          // layout="fixed"
                          alt={item.name}
                          className={styles.clickable()}
                        />
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={styles.middleContainer()}>
              <div className={styles.footerTitle()}>
                {t("home.our_contact")}
              </div>
              <div>
                {contact.map((item, idx) => (
                  <div key={idx} className={styles.footerText()}>
                    {item.text}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.creditContainer()}>
            <div className={styles.creditText()}>
              {t("home.footer_copyrights", {
                year: `${new Date().getFullYear()}`,
              })}
            </div>
            <div className={styles.creditWrapper()}>
              {credit.map((item, idx) => (
                <a className={styles.creditText()} href={item.link} key={idx}>
                  {item.text}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const styles = {
  qridText: css({
    transition: "transform 0.3s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  }),
  waveContainer: css({
    backgroundImage: "url('/assets/svg/footer-wave-mobile.svg')",
    "@sm": {
      backgroundImage: "url('/assets/svg/footer-wave.svg')",
    },
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: 80,
    position: "relative",
    top: "5px",
    mt: "$xxl",
    "@xl": {
      height: 120,
      mt: "$xxxl",
    },
    "@media (min-width: 2000px)": {
      height: 200,
    },
  }),
  container: css({
    backgroundColor: "#2F80ED",
    display: "flex",
    position: "relative",
  }),
  content: css({
    position: "relative",
    py: "$xl",
    pt: "$lg",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    // gap: 16,
    margin: "0 auto",
    px: "$xl",
    "@lg": {
      pt: "$xl",
    },
    "@xl": {
      px: 0,
      maxWidth: 1140,
    },
  }),
  rightContainer: css({
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
    "@sm": {
      flexBasis: "50%",
      ml: "$lg",
    },
    "@md": {
      flexBasis: "50%",
      ml: "$xxl",
    },
    "@xl": {
      flexBasis: "66.6667%",
      flexDirection: "row",
      justifyContent: "space-between",
      ml: 0,
    },
  }),
  buyImageContainer: css({
    display: "flex",
    justifyContent: "center",
  }),
  downloadImageContainer: css({
    display: "flex",
    flexDirection: "column",
  }),
  buyImageWrapper: css({
    mb: 20,
    "@lg": {
      mb: 15,
    },
  }),
  locationContainer: css({
    width: "100%",
    display: "flex",
    flexDirection: "column",
  }),
  qridContainer: css({
    width: "100%",
    display: "flex",
    flexDirection: "column",
  }),
  locationInner: css({
    display: "flex",
    marginBlock: 4,
    // "@md": {
    //   flexDirection: "column",
    //   alignItems: "center",
    // },
  }),
  contactContainer: css({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    mt: "$md",
    // "@md": {
    //   flexDirection: "row",
    //   gap: "$lg",
    // },
  }),
  contactInner: css({
    display: "flex",
    marginBlock: 4,
    "@md": {
      flexDirection: "row",
      alignItems: "center",
    },
  }),
  qridInner: css({
    display: "flex",
    marginBlock: 4,
    "@md": {
      flexDirection: "row",
      alignItems: "center",
    },
  }),
  icon: css({
    mt: 2,
    mr: "$sm",
    // "@lg": {
    //   width: "28px !important",
    //   height: "28px !important",
    // },
    // "@xl": {
    //   width: "32px !important",
    //   height: "32px !important",
    // },
  }),
  download: css({
    "@lg": {
      width: "154px !important",
      height: "48px !important",
    },
    "@xl": {
      width: "172px !important",
      height: "54px !important",
    },
  }),
  socialIconContainer: css({
    display: "flex",
    mt: 8,
  }),
  innerContainer: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    "@lg": {
      flexDirection: "row",
      alignItems: "flex-start",
      mb: "$xl",
    },
  }),
  childContainer: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    mb: "$xxl",
    flexBasis: "100%",
    "@sm": {
      mb: "$md",
      flexBasis: "50%",
    },
    "@md": {
      flexBasis: "50%",
      mb: "$md",
    },
    // "@lg": {
    //   ml: "$lg",
    // },
    "@xl": {
      // mr: "$xl",
      mb: "$lg",
    },
  }),
  leftContainer: css({
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    width: "100%",
    mb: 40,
    flexBasis: "100%",
    "@sm": {
      flexBasis: "50%",
    },
    "@md": {
      flexBasis: "50%",
      mb: "$md",
    },
    // "@lg": {
    //   flexBasis: "33.3333%",
    // },
    "@xl": {
      flexBasis: "33.3333%",
      mb: "$lg",
    },
  }),
  middleContainer: css({
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    width: "100%",
    mb: 40,
    flexBasis: "100%",
    "@sm": {
      flexBasis: "50%",
    },
    "@md": {
      flexBasis: "50%",
      mb: "$md",
    },
    "@lg": {
      // flexBasis: "33.3333%",
      paddingLeft: 60,
    },
    "@xl": {
      flexBasis: "33.3333%",
      mb: "$lg",
      paddingLeft: 60,
    },
  }),
  imageLink: css({
    "&:hover": {
      cursor: "pointer",
    },
  }),
  creditContainer: css({
    width: "100%",
    textAlign: "center",
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "start",
    "@md": {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    columnGap: 50,
  }),
  creditWrapper: css({
    display: "flex",
    flexDirection: "column",
    columnGap: 50,
    "@md": {
      flexDirection: "row",
    },
  }),
  creditText: css({
    color: "white",
    fontSize: 16,
    fontWeight: 500,
    mb: 20,
    "@md": {
      mt: "$sm",
      mb: 0,
    },
    lineHeight: "$sm",
    textAlign: "left",
  }),
  clickable: css({
    "&:hover": {
      cursor: "pointer",
    },
  }),
  footerText: css({
    fontSize: 16,
    fontWeight: 500,
    color: "white",
    lineHeight: "$sm",
  }),
  footerTitle: css({
    color: "white",
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "$sm",
    mb: "$md",
  }),
};
