import { css } from "common/utils/stitches.config";
import { FeatureCard, Tag } from "components/home-element";
import { useTranslation } from "react-i18next";

export default function Feature() {
  const { t } = useTranslation();

  return (
    <section>
      <div className={styles.container()}>
        <div className={styles.content()}>
          <div className={styles.textContainer()}>
            <Tag sectionTitle middleAccent className={styles.tagTitle()}>
              {t("home.feature")}
            </Tag>
          </div>
          <div className={styles.cardContainer()}>
            {Array(6)
              .fill(0)
              .map((_, idx) => {
                return (
                  <div key={idx} className={styles.cardWrapper()}>
                    <FeatureCard
                      icon={`/assets/icon-fitur-${idx + 1}.png`}
                      title={t(`home.feature_title_${idx + 1}`)}
                      description={t(`home.feature_description_${idx + 1}`)}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
}

const styles = {
  container: css({
    display: "flex",
    flexDirection: "column",
    backgroundImage: "linear-gradient(#E3EEF1,$ternary)",
    position: "relative",
    overflow: "hidden",
  }),
  content: css({
    position: "relative",
    zIndex: 1,
    pt: 36,
    pb: 36,
    // py: "$xl",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    px: "$xl",
    margin: "0 auto",
    "@xs": {
      maxWidth: 490,
    },
    "@sm": {
      maxWidth: 690,
    },
    "@md": {
      maxWidth: 740,
      pb: 72,
    },
    "@lg": {
      maxWidth: 900,
      py: "$xxl",
    },
    "@xl": {
      maxWidth: 1140,
      py: "$xxxl",
    },
  }),
  tagTitle: css({
    fontSize: "$lg",
  }),
  cardWrapper: css({
    py: 14,
    flexBasis: "100%",
    "@md": {
      flexBasis: "50%",
      px: 14,
    },
    "@xl": {
      flexBasis: "33.333%",
    },
  }),
  cardContainer: css({
    display: "flex",
    flexDirection: "column",
    "&:nth-of-type(2n+1)": {
      ml: "$lg",
    },
    "@md": {
      flexDirection: "row",
      flexWrap: "wrap",
    },
  }),
  textContainer: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexBasis: "100%",
    mt: "$lg",
    mb: "$sm",
    "@md": {
      flexBasis: "40%",
      mt: 0,
      mb: 0,
    },
  }),
};
