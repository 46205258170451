import React from "react";

interface Props {
  color?: string;
  size?: number;
}
function ArrowDownSvg(props: Props) {
  const { color = "#2F80ED", size = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill={color}
        d="M24.946 10.947a1.335 1.335 0 00-1.893 0l-6.107 6.106a1.333 1.333 0 01-1.893 0l-6.107-6.106a1.334 1.334 0 10-1.893 1.88l6.12 6.12a4 4 0 005.653 0l6.12-6.12a1.333 1.333 0 000-1.88z"
      />
    </svg>
  );
}

export default ArrowDownSvg;
