import React from "react";

interface Props {
  color?: string;
  size?: number;
}

function DoctorSvg(props: Props) {
  const { color = "#EB5757", size = 24 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <g clipPath="url(#clip0_6424_18487)">
        <path
          fill={color}
          d="M31.667 8.333h-5V6.666a5 5 0 00-5-5h-3.334a5 5 0 00-5 5v1.667h-5A8.343 8.343 0 000 16.666V30a8.343 8.343 0 008.333 8.333h23.334A8.344 8.344 0 0040 30V16.666a8.343 8.343 0 00-8.333-8.333zm-15-1.667A1.667 1.667 0 0118.333 5h3.334a1.667 1.667 0 011.666 1.667v1.666h-6.666V6.666zM25 25h-3.333v3.333a1.667 1.667 0 01-3.334 0V25H15a1.667 1.667 0 010-3.334h3.333v-3.333a1.666 1.666 0 113.334 0v3.334H25A1.667 1.667 0 0125 25z"
        />
      </g>
      <defs>
        <clipPath id="clip0_6424_18487">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DoctorSvg;
