import React from "react";

interface Props {
  color?: string;
  size?: number;
}

function BadgeHomeSvg(props: Props) {
  const { color = "#F2C94C", size = 24 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <g clipPath="url(#clip0_6295_19586)">
        <path
          fill={color}
          d="M20 26.667a13.333 13.333 0 1113.333-13.334 13.348 13.348 0 01-13.334 13.334zM20 30a16.564 16.564 0 01-10-3.365v9.198a4.166 4.166 0 006.77 3.254l2.71-2.167a.833.833 0 011.04 0l2.71 2.167A4.166 4.166 0 0030 35.833v-9.198A16.567 16.567 0 0120 30z"
        />
      </g>
      <defs>
        <clipPath id="clip0_6295_19586">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BadgeHomeSvg;
