import React from "react";

interface Props {
  color?: string;
  size?: number;
}

function PictureSvg(props: Props) {
  const { color = "#BB6BD9", size = 24 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <g fill={color} clipPath="url(#clip0_6295_19591)">
        <path d="M18.537 20.894a5 5 0 00-7.073 0l-11.4 11.4A8.318 8.318 0 008.333 40h23.333c1.633 0 3.229-.483 4.588-1.388L18.537 20.894zM30 13.334a3.333 3.333 0 100-6.667 3.333 3.333 0 000 6.667z" />
        <path d="M31.667 0H8.333A8.343 8.343 0 000 8.333v19.31l9.107-9.106a8.332 8.332 0 0111.786 0l17.719 17.718A8.281 8.281 0 0040 31.667V8.333A8.343 8.343 0 0031.667 0zM30 16.667a6.666 6.666 0 110-13.333 6.666 6.666 0 010 13.333z" />
      </g>
      <defs>
        <clipPath id="clip0_6295_19591">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PictureSvg;
