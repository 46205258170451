import React from "react";

interface Props {
  color?: string;
  size?: number;
}
function CommentHomeSvg(props: Props) {
  const { color = "#27AE60", size = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_6472_19045)">
        <path
          fill={color}
          d="M8.7 18H3c-1.493 0-3-1.134-3-3.666v-5.04A9.418 9.418 0 018.349.024a9 9 0 019.628 9.627 9.42 9.42 0 01-9.277 8.35zM20 9.08h-.012c0 .237 0 .474-.012.712-.386 5.408-5.329 9.986-10.892 10.19v.014A8.001 8.001 0 0016 24h5a3 3 0 003-3v-5a8 8 0 00-4-6.92z"
        />
      </g>
      <defs>
        <clipPath id="clip0_6472_19045">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CommentHomeSvg;
