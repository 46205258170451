import React from "react";

interface Props {
  color?: string;
  size?: number;
}
function ClockSvg(props: Props) {
  const { color = "#27AE60", size = 24 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <g clipPath="url(#clip0_6295_19553)">
        <path
          fill={color}
          d="M20 0a20 20 0 1020 20A20.021 20.021 0 0020 0zm1.667 19.798a1.667 1.667 0 01-.782 1.414l-6.4 4a1.67 1.67 0 01-1.77-2.834l5.618-3.503v-7.208a1.666 1.666 0 113.334 0v8.131z"
        />
      </g>
      <defs>
        <clipPath id="clip0_6295_19553">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ClockSvg;
