import React from "react";

interface Props {
  color?: string;
  size?: number;
}
function LabelHomeSvg(props: Props) {
  const { color = "#2F80ED", size = 24 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <g clipPath="url(#clip0_6454_18263)">
        <path
          fill={color}
          d="M3.333 11.962v19.705A8.343 8.343 0 0011.666 40h16.667a8.344 8.344 0 008.333-8.333V11.962a5 5 0 00-2.571-4.37L20.81.21a1.667 1.667 0 00-1.62 0L5.905 7.592a5 5 0 00-2.572 4.37zM20 9.167a2.5 2.5 0 110 5 2.5 2.5 0 010-5z"
        />
      </g>
      <defs>
        <clipPath id="clip0_6454_18263">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LabelHomeSvg;
