import { css } from "common/utils/stitches.config";
import { BenefitCard, Tag } from "components/home-element";
import * as React from "react";
import { useTranslation } from "react-i18next";

import AlarmClockHomeSvg from "../../../public/assets/svg/alarm-clock-home-svg";
import BadgeHomeSvg from "../../../public/assets/svg/badge-home-svg";
import ChartTreeSvg from "../../../public/assets/svg/chart-tree-svg";
import ClockSvg from "../../../public/assets/svg/clock-svg";
import CloudSvg from "../../../public/assets/svg/cloud-svg";
import CursorFingerSvg from "../../../public/assets/svg/cursor-finger-svg";
import InfinitySvg from "../../../public/assets/svg/infinity-svg";
import LabelHomeSvg from "../../../public/assets/svg/label-home-svg";
import LockSvg from "../../../public/assets/svg/lock-svg";
import PictureSvg from "../../../public/assets/svg/picture-svg";
import ReceiptSvg from "../../../public/assets/svg/receipt-svg";
import ShopSvg from "../../../public/assets/svg/shop-svg";
export default function Benefit() {
  const { t } = useTranslation();
  const Benefits = React.useMemo(() => {
    const size = 32;
    return [
      {
        title: "Cloud",
        icon: <CloudSvg size={size} />,
      },
      {
        title: "Kolaborasi",
        icon: <ChartTreeSvg size={size} />,
      },
      {
        title: "Mudah",
        icon: <CursorFingerSvg size={size} />,
      },
      {
        title: "Aman",
        icon: <LockSvg size={size} />,
      },
      {
        title: "Tak Terbatas",
        icon: <InfinitySvg size={size} />,
      },
      {
        title: "Inventaris",
        icon: <LabelHomeSvg size={size} />,
      },
      {
        title: "Kuitansi",
        icon: <ReceiptSvg size={size} />,
      },
      {
        title: "Garansi",
        icon: <BadgeHomeSvg size={size} />,
      },
      {
        title: "Riwayat",
        icon: <ClockSvg size={size} />,
      },
      {
        title: "Foto",
        icon: <PictureSvg size={size} />,
      },
      {
        title: "Reminder",
        icon: <AlarmClockHomeSvg size={size} />,
      },
      {
        title: "Bisnis",
        icon: <ShopSvg size={size} color="#2F80ED" />,
      },
    ];
  }, []);
  return (
    <section>
      <div className={styles.container()}>
        <div className={styles.content()}>
          <div className={styles.textContainer()}>
            <Tag sectionTitle middleAccent wavy>
              {t("home.benefit")}
            </Tag>
          </div>
          <div className={styles.cardContainer()}>
            {Array(12)
              .fill(0)
              .map((_, idx) => {
                return (
                  <div key={idx} className={styles.cardWrapper()}>
                    <BenefitCard
                      icon={Benefits[idx].icon}
                      title={t(`home.benefit_title_${idx + 1}`)}
                      description={t(`home.benefit_description_${idx + 1}`)}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
}

const styles = {
  container: css({
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflow: "hidden",
    backgroundImage:
      "linear-gradient(180deg, rgba(244, 249, 252, 0) 0%, #F4F9FC 100%)",
    "@md": {
      backgroundImage: "none",
      backgroundColor: "white",
    },
  }),
  content: css({
    position: "relative",
    zIndex: 1,
    pt: 36,
    pb: 36,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    px: "$xl",
    margin: "0 auto",
    "@xs": {
      maxWidth: 490,
    },
    "@sm": {
      maxWidth: 690,
    },
    "@md": {
      maxWidth: 740,
      pb: 72,
    },
    "@lg": {
      maxWidth: 900,
      py: "$xxl",
    },
    "@xl": {
      maxWidth: 1140,
      py: "$xxxl",
    },
  }),
  cardWrapper: css({
    // flexBasis: "100%",
    // "@md": {
    //   flexBasis: "50%",
    // },
    // "@xl": {
    //   flexBasis: "20%",
    // },
  }),
  cardContainer: css({
    display: "grid",
    mt: "$md",
    width: "100%",
    gridTemplateColumns: "auto",
    gap: 40,
    "@sm": {
      gap: 50,
      mt: "$xl",
      pl: "12%",
      gridTemplateColumns: "auto auto",
    },
    "@md": {
      pl: 0,
      gridTemplateColumns: "auto auto auto",
    },
    "@xl": {
      gridTemplateColumns: "auto auto auto auto",
    },
  }),
  textContainer: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexBasis: "100%",
    mt: "$lg",
    mb: "$sm",
    "@md": {
      flexBasis: "40%",
      mt: 0,
      mb: 0,
    },
    "@lg": {
      mb: "$md",
    },
  }),
};
