import React from "react";

interface Props {
  color?: string;
  size?: number;
}
function CubeSvg(props: Props) {
  const { color = "#F2C94C", size = 24 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill={color}
        d="M24.603 19.311a9.168 9.168 0 01-2.937 1.06V39.79a8.203 8.203 0 002.5-.929l10.045-5.798a8.36 8.36 0 004.167-7.218v-11.6a8.268 8.268 0 00-.442-2.617L24.603 19.31zM17.07 16.428a5.884 5.884 0 005.868 0l13.333-7.683a8.305 8.305 0 00-2.063-1.712L24.166 1.228a8.358 8.358 0 00-8.333 0l-10.045 5.8a8.29 8.29 0 00-2 1.639l13.282 7.761zM18.334 20.372a9.154 9.154 0 01-2.939-1.06l-13.3-7.773a8.26 8.26 0 00-.473 2.706v11.6a8.36 8.36 0 004.167 7.219l10.045 5.798a8.204 8.204 0 002.5.928V20.372z"
      />
    </svg>
  );
}

export default CubeSvg;
