import classNames from "classnames";
import { styled } from "common/utils/stitches.config";
import Image from "next/legacy/image";
import * as React from "react";

import Text from "./text";

export interface SolutionCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  industry?: boolean;
  className?: any;
}

export default function SolutionCard(props: SolutionCardProps) {
  const { icon, title, description, industry, ...restProps } = props;

  return (
    <Card
      industry={industry}
      {...restProps}
      className={classNames(props.className)}
    >
      <IconContainer>
        {icon}
        <TitleText>{title}</TitleText>
      </IconContainer>
      <DescriptionText>{description}</DescriptionText>
    </Card>
  );
}

const Card = styled("div", {
  borderRadius: 16,
  // boxShadow: "0 2px 12px rgb(14 31 80 / 10%)",
  px: "$md",
  "@sm": {
    px: 0,
    boxShadow: "none",
  },
  backgroundColor: "transparent",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  variants: {
    industry: {
      true: {
        alignItems: "center",
      },
    },
  },
});

const TitleText = styled(Text, {
  width: "100%",
  textTransform: "capitalize",
  fontWeight: 700,
  color: "#333333",
  fontSize: "$sm",
  lineHeight: "$sm",
  "@sm": {
    mt: 10,
  },
});

const DescriptionText = styled(Text, {
  color: "#828282",
  fontWeight: 500,
  fontSize: "$sm",
  lineHeight: "$sm",
  mt: "$sm",
  "@sm": {
    whiteSpace: "pre-line",
  },
  // textAlign: "center",
});

const IconContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 15,
  "@sm": {
    flexDirection: "column",
    alignItems: "start",
    gap: 0,
  },
});
