import { css, styled } from "common/utils/stitches.config";
import { Tag, Text } from "components/home-element";
import * as React from "react";
import { useTranslation } from "react-i18next";

import ArrowDown from "../../../public/assets/svg/arrow-down-svg";
import CommentHomeSvg from "../../../public/assets/svg/comment-home-svg";
export default function Faq() {
  const { t } = useTranslation();

  return (
    <section id="faq">
      <div className={styles.container()}>
        <div className={styles.content()}>
          <div className={styles.titleContainer()}>
            <Tag sectionTitle middleAccent>
              {t("home.faq")}
            </Tag>
          </div>

          <div className={styles.cardContainer()}>
            {Array(9)
              .fill(0)
              .map((_, idx) => {
                return (
                  <div key={idx} className={styles.cardWrapper()}>
                    <FaqCard
                      title={t(`home.faq_question_${idx + 1}`)}
                      description={t(`home.faq_answer_${idx + 1}`)}
                      idx={idx + 1}
                    />
                    {idx + 1 !== 9 && (
                      <div className={styles.waveSeparator()} />
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
}

export interface FaqCardProps {
  title: string;
  description: string;
  idx: number;
}

function FaqCard(props: FaqCardProps) {
  const { title, description, idx } = props;
  const [show, setShow] = React.useState<boolean>(false);

  const Links = {
    GooglePlay: "https://play.google.com/store/apps/details?id=co.qrid.android",
    AppStore: "https://apps.apple.com/id/app/qrid/id1592667444?l=id",
    Tokopedia: "https://www.tokopedia.com/qrid",
  };

  let content: React.ReactNode = null;
  switch (idx) {
    case 2:
      content = (
        <>
          Aplikasi QRID tersedia di{" "}
          <FaqLink link={Links.GooglePlay}>Google Play</FaqLink> dan{" "}
          <FaqLink link={Links.AppStore}>App Store</FaqLink> yang bisa kamu
          unduh dan gunakan secara gratis selamanya.
        </>
      );
      break;
    case 3:
      content = (
        <>
          Kamu harus memiliki stiker QRID untuk bisa mulai menggunakan QRID
          untuk mengelola aset (non living things) kamu. Kamu bisa beli stiker
          QRID dari <FaqLink link={Links.Tokopedia}>Tokopedia</FaqLink>.
        </>
      );
      break;
    default:
      content = <>{description}</>;
  }
  return (
    <Card>
      <div>
        <CommentHomeSvg size={24} />
      </div>

      <div style={{ marginLeft: 20, width: "100%" }}>
        <div style={{ display: "flex", width: "100%", alignItems: "start" }}>
          <TitleText>{title}</TitleText>
          <div
            className={styles.arrowDown()}
            style={{ transform: show ? "rotate(180deg)" : "initial" }}
            onClick={() => {
              setShow(!show);
            }}
          >
            <ArrowDown size={24} />
          </div>
        </div>
        <DescriptionText>{show && content}</DescriptionText>
      </div>
    </Card>
  );
}

interface FaqLinkProps {
  link: string;
  children: React.ReactNode;
}

function FaqLink(props: FaqLinkProps) {
  const { link, children } = props;
  return (
    <a target="_blank" href={link} className={styles.link()}>
      {children}
    </a>
  );
}

const Card = styled("div", {
  position: "relative",
  borderRadius: 16,
  padding: "1.75rem 0",
  backgroundColor: "white",
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
});

const TitleText = styled(Text, {
  fontWeight: 600,
  color: "#333333",
  lineHeight: "$sm",
  fontSize: 16,
  "@sm": {
    fontSize: "$sm",
  },
  mr: 10,
  // mt: "$md",
});

const DescriptionText = styled(Text, {
  color: "#828282",
  fontWeight: 400,
  lineHeight: "$sm",
  fontSize: 16,
  "@sm": {
    fontSize: "$sm",
  },
  mt: "$sm",
  overflow: "hidden",
  transition: "all 2s",
});

const styles = {
  container: css({
    display: "flex",
    flexDirection: "column",
  }),
  content: css({
    zIndex: 1,
    width: "100%",
    position: "relative",
  }),
  commentIcon: css({
    width: 24,
    height: 24,
    objectFit: "contain",
  }),
  arrowDown: css({
    padding: "9px 8px 0px 8px",
    borderRadius: "50%",
    "&:hover": {
      cursor: "pointer",
    },
    ml: "auto",
    // transition: "all 0.3s",
    // "&:active": {
    //   backgroundColor: "rgb(47, 128, 237,0.1)",
    // },
    position: "relative",
    top: "-9px",
  }),
  cardWrapper: css({
    py: 14,
  }),
  cardContainer: css({
    pt: 36,
    pb: 0,
    // py: "$xl",
    width: "100%",
    px: "$xl",
    margin: "0 auto",
    "@xs": {
      maxWidth: 490,
    },
    "@sm": {
      maxWidth: 690,
    },
    "@md": {
      maxWidth: 740,
      pb: 0,
    },
    "@lg": {
      maxWidth: 900,
      py: "$xxl",
      pb: 0,
    },
    "@xl": {
      maxWidth: 1140,
      pt: "$xxl",
      pb: 0,
    },
  }),
  link: css({
    color: "#2F80ED",
    fontWeight: 400,
    fontSize: "$sm",
    display: "inline",
  }),
  titleContainer: css({
    width: "100%",
    backgroundImage: "url('/assets/svg/wave-bg.svg')",
    height: 120,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@sm": {
      height: 150,
    },
  }),
  waveSeparator: css({
    height: 10,
    backgroundImage: "url('/assets/svg/wave-separator.svg')",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  }),
};
