import classNames from "classnames";
import { styled } from "common/utils/stitches.config";
import * as React from "react";

import Text from "./text";

export interface TagProps {
  children: React.ReactNode;
  sectionTitle?: boolean;
  noAccent?: boolean;
  footer?: boolean;
  middleAccent?: boolean;
  wavy?: boolean;
  className?: any;
  style?: React.CSSProperties;
  textTransform?: "uppercase" | "capitalize";
  color?: string;
}

export default function Tag(props: TagProps) {
  const {
    children,
    sectionTitle = false,
    noAccent = false,
    footer = false,
    middleAccent = false,
    wavy = false,
    textTransform = "capitalize",
    color = "black",
    ...restProps
  } = props;

  return (
    <TagContainer
      style={props.style}
      {...restProps}
      className={classNames(props.className)}
      footer={footer}
    >
      <TagText sectionTitle={sectionTitle} style={{ textTransform, color }}>
        {children}
      </TagText>
      {wavy && <WavyUnderline />}
    </TagContainer>
  );
}

const TagAccent = styled("div", {
  position: "absolute",
  // zIndex: -1,
  height: 4,
  width: "35%",
  backgroundColor: "$blue",
  bottom: -2,
  // left: -20,
  "@xl": {
    height: 6,
    width: "32.5%",
    backgroundColor: "$blue",
    bottom: 4,
  },
  variants: {
    middleAccent: {
      true: {
        left: "32.5%",
      },
    },
  },
});

const TagContainer = styled("div", {
  position: "relative",
  // display: "flex",
  // alignItems: "center",
  width: "fit-content",
  // backgroundColor: "$tagBackground",
  borderRadius: 8,
  mb: 8,
  variants: {
    footer: {
      true: {
        mb: 24,
      },
    },
  },
});

const TagText = styled(Text, {
  fontWeight: 700,
  textDecorationStyle: "wavy",
  textDecorationColor: "#2F80ED",
  color: "black",
  // zIndex: 2,
  width: "fit-content",
  // fontWeight: "$medium",
  // color: "#3995C6",
  // color: "$tagText",
  // textDecoration: "underline",
  borderRadius: 8,
  // px: 16,
  py: 2,
  fontSize: "$sm",
  lineHeight: "$sm",
  // "@md": {
  //   fontSize: "$xs",
  //   lineHeight: "$xs",
  // },
  // "@lg": {
  //   fontSize: "$sm",
  //   lineHeight: "$sm",
  // },
  "@xl": {
    fontSize: "$md",
    lineHeight: "$lg",
  },
  variants: {
    sectionTitle: {
      true: {
        fontSize: "32px",
        lineHeight: "$md",
        "@xl": {
          fontSize: "32px",
          lineHeight: "54px",
        },
      },
    },
  },
});

const WavyUnderline = styled("div", {
  width: "100%",
  height: 10,
  backgroundImage: "url('/assets/svg/wave-underline.svg')",
  backgroundSize: "contain",
  backgroundRepeat: "repeat-x",
});
