import React from "react";

interface Props {
  color?: string;
  size?: number;
}

function AlarmClockHomeSvg(props: Props) {
  const { color = "#EB5757", size = 24 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <g fill={color} clipPath="url(#clip0_6295_19569)">
        <path d="M40 7.5a1.667 1.667 0 11-3.333 0 4.316 4.316 0 00-4.642-4.167 1.666 1.666 0 110-3.333A7.667 7.667 0 0140 7.5zM3.333 7.5a4.317 4.317 0 014.642-4.167 1.667 1.667 0 000-3.333A7.667 7.667 0 000 7.5a1.667 1.667 0 003.333 0zM33.866 33.627A18.3 18.3 0 0021.667 3.418V1.667a1.667 1.667 0 00-3.333 0v1.751A18.3 18.3 0 006.136 33.627a5.04 5.04 0 00-2.802 4.706 1.666 1.666 0 103.333 0 2 2 0 011.85-2.048c.115-.022.227-.056.334-.103a18.207 18.207 0 0022.316 0c.098.045.2.08.304.106a2 2 0 011.863 2.045 1.666 1.666 0 103.333 0 5.04 5.04 0 00-2.801-4.706zm-7.687-7.449a1.666 1.666 0 01-2.357 0l-5-5A1.667 1.667 0 0118.334 20v-8.333a1.666 1.666 0 113.333 0v7.643l4.512 4.512a1.666 1.666 0 010 2.356z" />
      </g>
      <defs>
        <clipPath id="clip0_6295_19569">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AlarmClockHomeSvg;
