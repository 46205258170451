import type * as Polymorphic from "@radix-ui/react-polymorphic";
import { styled, CSS, StitchesVariants } from "common/utils/stitches.config";
import * as React from "react";

const DEFAULT_TAG = "span";

const StyledText = styled(DEFAULT_TAG, {
  // Reset
  lineHeight: "1",
  margin: "0",
  fontWeight: "$regular",
  fontVariantNumeric: "tabular-nums",
  display: "block",
  color: "$defaultFont",

  variants: {
    weight: {
      regular: {
        fontWeight: "$regular",
      },
      bold: {
        fontWeight: "$bold",
      },
    },
    align: {
      center: {
        textAlign: "center",
      },
      left: {
        textAlign: "left",
      },
      right: {
        textAlign: "right",
      },
    },
    size: {
      xxs: {
        fontSize: "$xxs",
      },
      xs: {
        fontSize: "$xs",
      },
      sm: {
        fontSize: "$sm",
      },
      md: {
        fontSize: "$md",
      },
      lg: {
        fontSize: "$lg",
      },
      xl: {
        fontSize: "$xl",
      },
      xxl: {
        fontSize: "$xxl",
      },
    },
    variant: {
      headline: {
        fontWeight: "$bold",

        // "@initial": {
        //   fontSize: "34px",
        //   lineHeight: "34px",
        // },
        "@xs": {
          fontSize: "$lg",
          lineHeight: "$lg",
        },
        "@sm": {
          fontSize: "$lg",
          lineHeight: "$lg",
        },
        "@md": {
          fontSize: "$lg",
          lineHeight: "$lg",
        },
        "@lg": {
          fontSize: "$lg",
          lineHeight: "$lg",
        },
        "@xl": {
          fontSize: "$xl",
          lineHeight: "$xl",
        },
      },
      subhead: {
        fontSize: "$md",
        fontWeight: "$bold",
        lineHeight: "64px",
      },
      title: {
        // "@initial": {
        //   fontSize: "$xs",
        //   lineHeight: "22px",
        // },
        "@xs": {
          fontSize: "16px",
          lineHeight: "24px",
        },
        "@sm": {
          fontSize: "16px",
          lineHeight: "24px",
        },
        "@md": {
          fontSize: "16px",
          lineHeight: "24px",
        },
        "@lg": {
          fontSize: "$sm",
          lineHeight: "$sm",
        },
        "@xl": {
          fontSize: "$sm",
          lineHeight: "$sm",
        },
      },
      sectionTitle: {
        fontWeight: "$bold",
        marginBottom: "$xl",

        // "@initial": {
        //   fontSize: "28px",
        //   lineHeight: "28px",
        // },
        "@xs": {
          fontSize: "34px",
          lineHeight: "34px",
        },
        "@sm": {
          fontSize: "34px",
          lineHeight: "34px",
        },
        "@md": {
          fontSize: "34px",
          lineHeight: "34px",
        },
        "@lg": {
          fontSize: "34px",
          lineHeight: "34px",
        },
        "@xl": {
          fontSize: "$lg",
          lineHeight: "$lg",
        },
      },
      subtitle: {},
      body: {
        // fontSize: "$xs",
        // lineHeight: "$xs",
      },
      subBody: {
        fontSize: "$xs",
        lineHeight: "$xs",
        fontWeight: "$bold",
      },
      caption: {
        // "@initial": {
        //   fontSize: "$xs",
        //   lineHeight: "$xs",
        // },
        "@xs": {
          fontSize: "$sm",
          lineHeight: "$sm",
        },
        "@sm": {
          fontSize: "$sm",
          lineHeight: "$sm",
        },
        "@md": {
          fontSize: "$sm",
          lineHeight: "$sm",
        },
        "@lg": {
          fontSize: "$sm",
          lineHeight: "$sm",
        },
        "@xl": {
          fontSize: "$sm",
          lineHeight: "$sm",
        },
      },
      subCaption: {
        // "@initial": {
        //   fontSize: "14px",
        //   lineHeight: "$xs",
        // },
      },
      smallCaption: {
        // "@initial": {
        //   fontSize: 12,
        //   lineHeight: "$xs",
        // },
      },
      callout: {},
    },
  },
  defaultVariants: {
    variant: "body",
  },
});

type TextCSSProp = { css?: CSS };
type TextVariants = StitchesVariants<typeof StyledText>;
type TextOwnProps = TextCSSProp & TextVariants;

type TextComponent = Polymorphic.ForwardRefComponent<
  typeof DEFAULT_TAG,
  TextOwnProps
>;

const Text = React.forwardRef((props, forwardedRef) => {
  return <StyledText {...props} ref={forwardedRef} />;
}) as TextComponent;

export default Text;

Text.toString = () => `.${StyledText.className}`;
