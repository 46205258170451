import { css } from "common/utils/stitches.config";
import { Button, Tag, Text } from "components/home-element";
import Image from "next/legacy/image";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";

import waveMobile from "../../../public/assets/S1-blue-wave-mobile.png";
import waveDesktop from "../../../public/assets/S1-blue-wave.png";
import phone1 from "../../../public/assets/S1-phone-LA.png";
import phone2 from "../../../public/assets/S3-phone-DVA.png";

export default function Sticker() {
  const { t } = useTranslation();

  return (
    <section id="history">
      <div className={styles.container()}>
        <div className={styles.content()}>
          <div className={styles.textContainer()}>
            <div className={styles.subheading()}>
              {t("home.history_subheading")}
            </div>

            <div className={styles.heading()}>
              <Text
                css={{
                  color: "#333333",
                  fontWeight: 700,
                  lineHeight: "$lg",
                  fontSize: 36,
                  mb: "$sm",
                  "@md": {
                    fontSize: 32,
                    width: "100%",
                    whiteSpace: "pre-line",
                  },
                  "@xl": {
                    fontSize: "$xl",
                    lineHeight: "56px",
                    mb: 16,
                  },
                }}
              >
                {t("home.history_heading")}
              </Text>
            </div>
            <div className={styles.description()}>
              <Text
                css={{
                  color: "#828282",
                  fontSize: "$sm",
                  fontWeight: 500,
                  lineHeight: "$sm",
                  mb: "$md",
                  "@md": {
                    fontSize: 16,
                    mb: "$lg",
                  },
                  "@lg": {
                    whiteSpace: "pre-line",
                    mb: "$xl",
                  },
                  "@xl": {
                    fontSize: "20px",
                    lineHeight: "36px",
                  },
                }}
              >
                {t("home.history_description")}
              </Text>
            </div>
            <Link activeClass="active" spy offset={-80} to="benefit">
              <Button buttonType="blueWave">{t("home.learn_more")}</Button>
            </Link>
          </div>
          <div className={styles.imageContent()}>
            <div className={styles.waveDesktopContainer()}>
              <Image src={waveDesktop} alt="history" priority />
            </div>
            <div className={styles.waveMobileContainer()}>
              <Image src={waveMobile} alt="history" priority />
            </div>
            <div className={styles.firstPhoneContainer()}>
              <Image src={phone1} alt="history" priority />
            </div>
            <div className={styles.secondPhoneContainer()}>
              <Image src={phone2} alt="history" priority />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const styles = {
  container: css({
    display: "flex",
    position: "relative",
    overflow: "hidden",
    backgroundColor: "white",
  }),
  content: css({
    position: "relative",
    // pt: 72,
    // pb: 64,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    "@md": {
      flexDirection: "row",
    },
    "@lg": {
      pl: "10%",
      pr: 0,
    },
  }),
  subheading: css({
    color: "#2F80ED",
    fontWeight: 600,
    textTransform: "uppercase",
    py: 2,
    fontSize: 14,
    mb: 18,
    "@xl": {
      fontSize: "$sm",
      lineHeight: "$sm",
    },
  }),
  heading: css({
    width: "100%",
    position: "relative",
    "@md": {
      width: "130%",
    },
  }),
  description: css({
    width: "100%",
    position: "relative",
    "@md": {
      width: "130%",
    },
  }),
  textContainer: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    flexBasis: "100%",
    mt: "$lg",
    px: "$xl",
    "@md": {
      flexBasis: "30%",
      mt: 0,
    },
    "@lg": {
      px: 0,
    },
  }),
  diagramContainer: css({
    position: "relative",
    display: "flex",
    width: "100%",
    mt: "$xl",
  }),
  imageContent: css({
    position: "relative",
    mt: "$md",
    width: "100%",
    "@md": {
      mt: 0,
      flexBasis: "65%",
      ml: "auto",
    },
  }),
  waveDesktopContainer: css({
    display: "none",
    "@md": {
      display: "flex",
      alignItems: "end",
      position: "relative",
      top: 0,
      right: 0,
      width: "110%",
    },
    "@lg": {
      width: "100%",
    },
  }),
  waveMobileContainer: css({
    display: "flex",
    alignItems: "end",
    position: "relative",
    top: 0,
    right: 0,
    width: "100%",
    "@md": {
      display: "none",
    },
  }),
  firstPhoneContainer: css({
    position: "absolute",
    zIndex: 1,
    width: "50%",
    left: "10%",
    top: "28%",
    "@md": {
      width: "34%",
      left: "28%",
      top: "10%",
    },
    "@lg": {
      width: "30%",
    },
  }),
  secondPhoneContainer: css({
    position: "absolute",
    zIndex: 2,
    width: "50%",
    left: "40%",
    top: "28%",
    "@md": {
      width: "34%",
      left: "50%",
      top: "10%",
    },
    "@lg": {
      width: "30%",
    },
  }),
  cardImage: css({
    width: "70%",
    mt: "$lg",
    mb: "$xxl",
  }),
  phoneImage: css({
    width: "100%",
    mt: "$lg",
  }),
  image: css({
    background: "red",
    "@lg": {
      width: "500px !important",
      height: "500px !important",
    },
  }),
};
