import React from "react";
interface Props {
  color?: string;
  size?: number;
}
function CursorFingerSvg(props: Props) {
  const { color = "#BB6BD9", size = 24 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <g clipPath="url(#clip0_6456_18269)">
        <path
          fill={color}
          d="M29.968 15.627l-8.301-1.994V4.3A4.273 4.273 0 0018.188.056a4.167 4.167 0 00-4.854 4.11v24.167a1.666 1.666 0 11-3.334 0V16.1l-4.648 6.066a8.368 8.368 0 00.415 11.324l4 4.041A8.393 8.393 0 0015.69 40h12.644a8.333 8.333 0 008.333-8.334v-7.868a8.333 8.333 0 00-6.699-8.171z"
        />
      </g>
      <defs>
        <clipPath id="clip0_6456_18269">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CursorFingerSvg;
