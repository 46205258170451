import React from "react";

interface Props {
  color?: string;
  size?: number;
}
function ReceiptSvg(props: Props) {
  const { color = "#27AE60", size = 24 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <g clipPath="url(#clip0_6295_19576)">
        <path
          fill={color}
          d="M13.333 0A8.343 8.343 0 005 8.333v30a1.666 1.666 0 002.607 1.375l3.51-2.398 3.51 2.398a1.666 1.666 0 001.883 0l3.5-2.398 3.5 2.398a1.667 1.667 0 001.885 0l3.5-2.396 3.5 2.395A1.667 1.667 0 0035 38.333v-30A8.343 8.343 0 0026.667 0H13.333zm10 23.333h-10a1.667 1.667 0 010-3.333h10a1.666 1.666 0 110 3.333zm5-8.333a1.667 1.667 0 01-1.666 1.667H13.333a1.667 1.667 0 010-3.334h13.334A1.667 1.667 0 0128.333 15z"
        />
      </g>
      <defs>
        <clipPath id="clip0_6295_19576">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ReceiptSvg;
