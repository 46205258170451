import React from "react";
interface Props {
  color?: string;
  size?: number;
}
function HandLoveSvg(props: Props) {
  const { color = "#BB6BD9", size = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <g clipPath="url(#clip0_6431_18206)">
        <path
          fill={color}
          d="M26.667 4.583c0 3.605-4.7 8.085-7.042 9.964a2.065 2.065 0 01-2.583 0C14.7 12.667 10 8.188 10 4.583A4.39 4.39 0 0114.167 0a4.39 4.39 0 014.166 4.583A4.39 4.39 0 0122.5 0a4.39 4.39 0 014.167 4.583zm12.23 16L26.063 34.598A16.667 16.667 0 0113.77 40H6.667A6.667 6.667 0 010 33.333V25a6.667 6.667 0 016.667-6.667h12.978a3.698 3.698 0 013.607 4.475 3.8 3.8 0 01-3.234 2.887l-6.938.972a1.667 1.667 0 00.472 3.3l7.086-1a7.057 7.057 0 006.029-6.945 6.8 6.8 0 00-.109-1.064l5.904-6.228a4.343 4.343 0 017.526 2.727 4.36 4.36 0 01-1.091 3.118v.008z"
        />
      </g>
      <defs>
        <clipPath id="clip0_6431_18206">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default HandLoveSvg;
