import React from "react";
interface Props {
  color?: string;
  size?: number;
}
function CloudSvg(props: Props) {
  const { color = "#2F80ED", size = 24 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill={color}
        d="M29.653 12.02A13.334 13.334 0 003.333 15c-.001 2.13.513 4.23 1.5 6.118a9.15 9.15 0 004.333 17.215h17.5a13.334 13.334 0 002.987-26.313z"
      />
    </svg>
  );
}

export default CloudSvg;
