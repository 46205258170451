export { default as Jumbotron } from "./jumbotron";
export { default as History } from "./history";
export { default as Service } from "./service";
export { default as Workshop } from "./workshop";
export { default as Vehicle } from "./vehicle";
export { default as Benefit } from "./benefit";
export { default as Feature } from "./feature";
export { default as Solution } from "./solution";
export { default as Faq } from "./faq";
export { default as Footer } from "./footer";
export { default as App } from "./app";
export { default as Sticker } from "./sticker";
