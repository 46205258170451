import React from "react";
interface Props {
  color?: string;
  size?: number;
}
function InfinitySvg(props: Props) {
  const { color = "#F2994A", size = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <g clipPath="url(#clip0_6295_19581)">
        <path
          fill={color}
          d="M30 10c-4.953 0-8.238 3.712-10 6.47C18.238 13.712 14.953 10 10 10a10 10 0 000 20c4.953 0 8.238-3.712 10-6.47C21.762 26.288 25.047 30 30 30a10 10 0 000-20zM10 26.667a6.667 6.667 0 010-13.334c4.488 0 7.303 4.909 8.167 6.667-.857 1.767-3.654 6.667-8.167 6.667zm20 0c-4.488 0-7.303-4.909-8.167-6.667.859-1.763 3.655-6.667 8.167-6.667a6.666 6.666 0 110 13.334z"
        />
      </g>
      <defs>
        <clipPath id="clip0_6295_19581">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default InfinitySvg;
