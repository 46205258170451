import React from "react";
interface Props {
  color?: string;
  size?: number;
}
function GymSvg(props: Props) {
  const { color = "#F2994A", size = 24 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <g clipPath="url(#clip0_6424_18460)">
        <path
          fill={color}
          d="M38.237 11.395L34.6 7.757l1.578-1.579a1.666 1.666 0 10-2.356-2.356L32.243 5.4l-3.638-3.637a6.167 6.167 0 00-8.508 0 6.016 6.016 0 000 8.51l3.636 3.637-9.823 9.823-3.638-3.636a6.167 6.167 0 00-8.508 0 6.016 6.016 0 000 8.51L5.4 32.243l-1.578 1.579a1.668 1.668 0 102.357 2.356L7.757 34.6l3.638 3.637a6.014 6.014 0 008.509 0 6.014 6.014 0 000-8.509l-3.637-3.636 9.833-9.825 3.637 3.636a6.016 6.016 0 108.508-8.508h-.008z"
        />
      </g>
      <defs>
        <clipPath id="clip0_6424_18460">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default GymSvg;
