import React from "react";
interface Props {
  color?: string;
  size?: number;
}
function HomeHomeSvg(props: Props) {
  const { color = "#BB6BD9", size = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <g fill={color} clipPath="url(#clip0_6433_18226)">
        <path d="M20 24.986a5 5 0 00-5 5v10h10v-10a5 5 0 00-5-5z" />
        <path d="M28.333 29.987v10H35a5 5 0 005-5V19.785c0-.866-.336-1.698-.938-2.32L24.898 2.153a6.667 6.667 0 00-9.791 0L.968 17.46A3.333 3.333 0 000 19.81v15.177a5 5 0 005 5h6.667v-10c.03-4.545 3.7-8.256 8.13-8.363 4.579-.11 8.501 3.664 8.536 8.363z" />
        <path d="M20 24.986a5 5 0 00-5 5v10h10v-10a5 5 0 00-5-5z" />
      </g>
      <defs>
        <clipPath id="clip0_6433_18226">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default HomeHomeSvg;
