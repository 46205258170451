import React from "react";

interface Props {
  color?: string;
  size?: number;
}

function FlagSvg(props: Props) {
  const { color = "#27AE60", size = 24 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <g clipPath="url(#clip0_6436_18248)">
        <path
          fill={color}
          d="M1.667 40A1.667 1.667 0 010 38.333V6.667A6.667 6.667 0 016.667 0h11.666A6.667 6.667 0 0125 6.667V15a6.667 6.667 0 01-6.667 6.667h-15v16.666A1.666 1.666 0 011.667 40zM33.333 6.667h-5V15a10.01 10.01 0 01-10 10h-.74a6.645 6.645 0 005.74 3.333h10A6.667 6.667 0 0040 21.667v-8.334a6.667 6.667 0 00-6.667-6.666z"
        />
      </g>
      <defs>
        <clipPath id="clip0_6436_18248">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FlagSvg;
