import React from "react";
interface Props {
  color?: string;
  size?: number;
}
function SettingHomeSvg(props: Props) {
  const { color = "#F2994A", size = 24 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill={color}
        d="M2.678 30a5 5 0 006.828 1.836c.002 0 .003-.002.005-.003l.742-.428a14.963 14.963 0 004.746 2.74V35a5 5 0 0010 0v-.855a14.96 14.96 0 004.747-2.743l.745.43a5.003 5.003 0 105-8.667l-.74-.427c.333-1.812.333-3.67 0-5.483l.74-.427a5.003 5.003 0 00-5-8.666l-.742.428A14.962 14.962 0 0025 5.855V5a5 5 0 00-10 0v.855a14.96 14.96 0 00-4.747 2.743l-.744-.431a5.003 5.003 0 10-5 8.666l.74.427a15.168 15.168 0 000 5.483l-.74.427A5.01 5.01 0 002.678 30zm17.321-16.667a6.667 6.667 0 110 13.334 6.667 6.667 0 010-13.334z"
      />
    </svg>
  );
}

export default SettingHomeSvg;
