import React from "react";
interface Props {
  color?: string;
  size?: number;
}
function BuildingSvg(props: Props) {
  const { color = "#2F80ED", size = 24 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <g clipPath="url(#clip0_6424_18478)">
        <path
          fill={color}
          d="M15 0H8.333A8.333 8.333 0 000 8.333v23.334A8.333 8.333 0 008.333 40h15V8.333A8.333 8.333 0 0015 0zM8.333 31.667H6.667a1.667 1.667 0 110-3.334h1.666a1.667 1.667 0 110 3.334zm0-6.667H6.667a1.667 1.667 0 110-3.333h1.666a1.667 1.667 0 010 3.333zm0-6.667H6.667a1.667 1.667 0 010-3.333h1.666a1.667 1.667 0 110 3.333zm0-6.666H6.667a1.667 1.667 0 110-3.334h1.666a1.667 1.667 0 010 3.334zm8.334 20H15a1.666 1.666 0 110-3.334h1.667a1.667 1.667 0 010 3.334zm0-6.667H15a1.666 1.666 0 110-3.333h1.667a1.667 1.667 0 010 3.333zm0-6.667H15A1.667 1.667 0 0115 15h1.667a1.667 1.667 0 010 3.333zm0-6.666H15a1.666 1.666 0 110-3.334h1.667a1.667 1.667 0 010 3.334zm15-3.334h-5V40h5A8.343 8.343 0 0040 31.667v-15a8.343 8.343 0 00-8.333-8.334zm1.666 23.334a1.667 1.667 0 110-3.334 1.667 1.667 0 010 3.334zm0-6.667a1.666 1.666 0 110-3.332 1.666 1.666 0 010 3.332zm0-6.667a1.666 1.666 0 110-3.332 1.666 1.666 0 010 3.332z"
        />
      </g>
      <defs>
        <clipPath id="clip0_6424_18478">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BuildingSvg;
